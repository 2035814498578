import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { X, PlusCircle, Trash2 } from "lucide-react";
import { useDatabase } from "@/contexts/DatabaseContext/DatabaseContext";
import { Q } from "@nozbe/watermelondb";
import { useDevice } from "@/contexts/DeviceContext";
import { useToast } from "@/components/ui/use-toast";
import { useDataScanner } from "@/lib/hooks";
import { QRCodeSVG } from "qrcode.react";
import { useDebounce } from "@/lib/utils";

const DATA_DELIMITER = "__DATA__";

export const Scale = () => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [inventoryHelperText, setInventoryHelperText] = useState("");
  const [isBarcodeScannerMode, setIsBarcodeScannerMode] = useState(false);
  const [scannedData, setScannedData] = useState("");
  const [lastScannedData, setLastScannedData] = useState(null);
  const [isQRPreviewMode, setIsQRPreviewMode] = useState(false);
  const [qrFields, setQRFields] = useState([
    { key: "productNo", label: t("scale.item_details.item_no"), value: "" },
    { key: "productName", label: t("scale.item_details.item_name"), value: "" },
  ]);
  const [scaleData, setScaleData] = useState({
    totalWeight: 0,
    totalWeightUnit: "g",
    singleWeight: 0,
    singleWeightUnit: "g",
    quantity: 0,
    quantityUnit: "pcs",
    tareWeight: 0,
    tareWeightUnit: "g",
    netWeight: 0,
    netWeightUnit: "g",
    status: "",
  });

  const [itemDetails, setItemDetails] = useState({
    productNo: {
      label: t("scale.item_details.item_no"),
      value: "",
      reuse: false,
    },
    productName: {
      label: t("scale.item_details.item_name"),
      value: "",
      reuse: false,
    },
  });

  const [newFieldName, setNewFieldName] = useState("");
  const { database } = useDatabase();
  const { devices } = useDevice();
  const [allowDuplicates, setAllowDuplicates] = useState(false);
  const { jsonData, error } = useDataScanner("__DATA__", 1024);
  const inputRefs = React.useRef({});
  const textareaRef = React.useRef(null);
  const [inventoryItemNo, setInventoryItemNo] = useState("");
  const debouncedInventoryItemNo = useDebounce(inventoryItemNo, 300);
  const prevScaleDataRef = useRef(null);

  const generateQRData = React.useCallback(() => {
    const data = {};
    qrFields.forEach((field) => {
      data[field.label] = field.value;
    });
    const jsonString = JSON.stringify(data);
    return `${DATA_DELIMITER}${btoa(encodeURIComponent(jsonString))}${DATA_DELIMITER}`;
  }, [qrFields]);

  const handleAddQRField = () => {
    if (newFieldName) {
      setQRFields((prev) => [
        ...prev,
        { key: newFieldName, label: newFieldName, value: "" },
      ]);
      setNewFieldName("");
    }
  };

  const handleQRFieldChange = (index, value) => {
    setQRFields((prev) =>
      prev.map((field, i) => (i === index ? { ...field, value } : field))
    );
  };

  const handleRemoveQRField = (index) => {
    setQRFields((prev) => prev.filter((_, i) => i !== index));
  };

  const handleClearAllQRFields = () => {
    setQRFields(qrFields.map(field => ({ ...field, value: "" })));
  };


  const decodeBase64 = (str) => {
    try {
      return decodeURIComponent(atob(str));
    } catch (e) {
      console.error("Failed to decode base64:", e);
      throw new Error(t("scale.invalid_base64"));
    }
  };

  const convertScannedDataToItemDetails = React.useCallback(
    (scannedData) => {
      const newItemDetails = { ...itemDetails };

      Object.entries(scannedData).forEach(([label, value]) => {
        let key;
        if (label === t("scale.item_details.item_no")) {
          key = "productNo";
        } else if (label === t("scale.item_details.item_name")) {
          key = "productName";
        } else {
          key = label;
        }

        if (key in newItemDetails) {
          newItemDetails[key] = {
            ...newItemDetails[key],
            value: value,
          };
        } else {
          newItemDetails[key] = {
            label: label,
            value: value,
            reuse: false,
          };
        }
      });

      return newItemDetails;
    },
    [itemDetails, t]
  );

  const handleBarcodeScan = (e) => {
    const input = e.target.value;
    setScannedData(input);

    if (input.startsWith(DATA_DELIMITER) && input.endsWith(DATA_DELIMITER)) {
      const payload = input.slice(
        DATA_DELIMITER.length,
        -DATA_DELIMITER.length
      );
      if (payload.length > 0) {
        try {
          const jsonStr = decodeBase64(payload);
          const scannedJsonData = JSON.parse(jsonStr);
          const newItemDetails =
            convertScannedDataToItemDetails(scannedJsonData);

          setItemDetails(newItemDetails);
          handleSave();
          setLastScannedData(scannedJsonData);
        } catch (error) {
          toast({
            title: t("common.error"),
            description: t("scale.invalid_barcode_data"),
            variant: "destructive",
          });
          console.error(error);
        } finally {
          setScannedData("");
          e.target.focus();
        }
      }
    }
  };

  const handleDeleteLastScannedData = () => {
    setLastScannedData(null);
  };

  const handleKeyDown = (e, field) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const fields = Object.keys(itemDetails);
      const currentIndex = fields.indexOf(field);
      const nextField = fields[currentIndex + 1];

      if (nextField) {
        inputRefs.current[nextField].focus();
      } else if (!loading && devices.scales.length > 0) {
        handleSave();
      }
    }
  };

  const checkDuplicateItemNo = React.useCallback(
    async (itemNo) => {
      const existingItems = await database
        .get("measurements")
        .query(Q.where("product_no", itemNo))
        .fetch();
      return existingItems.length > 0;
    },
    [database]
  );

  const handleClear = React.useCallback(() => {
    setItemDetails(
      Object.fromEntries(
        Object.entries(itemDetails).map(([key, { label, reuse }]) => [
          key,
          { label, value: reuse ? itemDetails[key].value : "", reuse },
        ])
      )
    );

    // Focus on the inventory item number input after clearing
    if (inputRefs.current.inventoryItemNo) {
      inputRefs.current.inventoryItemNo.focus();
    }
  }, [itemDetails]);

  const handleSave = React.useCallback(
    async (e) => {
      if (e) e.preventDefault();

      try {
        setLoading(true);
        if (!devices.scales.length) {
          toast({
            title: t("common.error"),
            description: t("scale.no_scale_description"),
            variant: "destructive",
          });
          return;
        }

        const isDuplicate = await checkDuplicateItemNo(
          itemDetails.productNo.value
        );
        if (isDuplicate && !allowDuplicates) {
          toast({
            title: t("common.error"),
            description: t("scale.duplicate_item_no"),
            variant: "destructive",
          });
          return;
        }
        await database.write(async () => {
          const batch = [];

          const scaleDevice = await database
            .get("devices")
            .query(
              Q.where("name", devices.scales[0].adapter.device.name),
              Q.take(1)
            )
            .fetch();

          const scale = (await scaleDevice[0].scales)[0];

          const measurement = database
            .get("measurements")
            .prepareCreate((measurement) => {
              measurement.timestamp = Date.now();
              measurement.tareWeight = scaleData.tareWeight;
              measurement.tareUnit = scaleData.tareWeightUnit;
              measurement.totalWeight = scaleData.totalWeight;
              measurement.totalUnit = scaleData.totalWeightUnit;
              measurement.singleWeight = scaleData.singleWeight;
              measurement.singleUnit = scaleData.singleWeightUnit;
              measurement.netWeight = scaleData.netWeight;
              measurement.netUnit = scaleData.netWeightUnit;
              measurement.name = itemDetails.productName.value;
              measurement.productNo = itemDetails.productNo.value;
              measurement.scale.set(scale);
            });
          batch.push(measurement);

          for (const [field, { label, value }] of Object.entries(itemDetails)) {
            if (field === "productNo" || field === "productName") continue;
            batch.push(
              database
                .get("measurement_fields")
                .prepareCreate((measurementField) => {
                  measurementField.fieldName = label;
                  measurementField.fieldValue = value;
                  measurementField.measurement.set(measurement);
                })
            );
          }

          await database.batch(...batch);
        });

        console.log("Saving item details:", itemDetails);
        toast({
          title: t("scale.save_success"),
          description: t("scale.save_success_description"),
        });
        handleClear();

        // Focus on the first input after saving
        const firstField = Object.keys(itemDetails)[0];
        if (inputRefs.current[firstField]) {
          inputRefs.current[firstField].focus();
        }
      } catch (error) {
        console.error("Failed to save item details:", error);
      } finally {
        setLoading(false);
      }
    },
    [
      devices,
      itemDetails,
      allowDuplicates,
      checkDuplicateItemNo,
      database,
      handleClear,
      scaleData,
      toast,
      t,
    ]
  );

  const handleAddField = () => {
    if (newFieldName && !Object.keys(itemDetails).includes(newFieldName)) {
      setItemDetails((prev) => ({
        ...prev,
        [newFieldName]: { label: newFieldName, value: "", reuse: false },
      }));
      setNewFieldName("");
    }
  };

  const handleInputChange = (field, value) => {
    setItemDetails((prev) => ({
      ...prev,
      [field]: { ...prev[field], value },
    }));
  };

  const handleSwitchChange = (field) => {
    setItemDetails((prev) => ({
      ...prev,
      [field]: { ...prev[field], reuse: !prev[field].reuse },
    }));
  };

  const handleRemoveField = (fieldToRemove) => {
    setItemDetails((prev) => {
      const { [fieldToRemove]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleScannerModeChange = (checked) => {
    setIsBarcodeScannerMode(checked);
    if (checked) {
      // Schedule focus for the next render cycle
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      }, 0);
    }
  };

  const handleInventoryItemNoChange = async (e) => {
    const value = e.target.value;
    setInventoryItemNo(value);
  };

  const fetchInventoryItem = async (value) => {
    if (value) {
      try {
        setInventoryHelperText("");
        const itemsCollection = database.get("inventory_items");
        const matchingItems = await itemsCollection.query(
          Q.where('key_value', value)
        ).fetch();
        const allItems = await itemsCollection.query().fetch();

        console.log(allItems, matchingItems)
  
        if (matchingItems.length > 0) {
          const matchingItem = matchingItems[0];
          const fields = await matchingItem.fields.fetch();
          
          const newItemDetails = { ...itemDetails };
          //newItemDetails.productNo = { ...newItemDetails.productNo, value: matchingItem.keyValue };
          
          fields.forEach((field) => {
            if (field.fieldName in newItemDetails) {
              newItemDetails[field.fieldName] = { ...newItemDetails[field.fieldName], value: field.fieldValue };
            } else {
              newItemDetails[field.fieldName] = { label: field.fieldName, value: field.fieldValue, reuse: false };
            }
          });
  
          setItemDetails(newItemDetails);
        } else {
          // Clear fields and show helper text if no matching item is found
          handleClear();
          setInventoryHelperText(t("scale.inventory.not_found_help", "找不到物料，請至物料庫新增。"));
        }
      } catch (err) {
        console.error("Failed to fetch inventory item:", err);
        toast({
          title: t("common.error"),
          description: t("scale.error.failed_fetching_inventory_item"),
          variant: "destructive",
        });
      }
    } else {
      // Clear fields if input is empty
      handleClear();
    }
  };
  
  useEffect(() => {
    fetchInventoryItem(debouncedInventoryItemNo);
  }, [debouncedInventoryItemNo]);

  React.useEffect(() => {
    // load fields from database
    if (!database) return;

    const fetchFields = async () => {
      const lastMeasurement = await database
        .get("measurements")
        .query(Q.sortBy("created_at", Q.desc), Q.take(1))
        .fetch();

      if (lastMeasurement.length === 0) return;
      const fields = await lastMeasurement[0].measurementFields.fetch();
      const newFields = {};

      for (const field of fields) {
        newFields[field.fieldName] = {
          label: field.fieldName,
          value: "",
          reuse: false,
        };
      }

      setItemDetails((prev) => ({
        ...prev,
        ...newFields,
      }));
    };

    fetchFields();
  }, [database]);

  React.useEffect(() => {
    if (devices.scales.length === 0) {
      setScaleData((prev) => ({
        ...prev,
        status: t("scale.status.disconnected"),
      }));
      prevScaleDataRef.current = null;
      return;
    }

    devices.scales.forEach((scale) => {
      scale._onMeasurement = (data) => {
        // Check if the new values are actually different from the previous ones
        const hasChanged = !prevScaleDataRef.current || 
          prevScaleDataRef.current.totalWeight !== data.totalWeight ||
          prevScaleDataRef.current.singleWeight !== data.singleWeight ||
          prevScaleDataRef.current.quantity !== data.quantity ||
          prevScaleDataRef.current.tareWeight !== data.tareWeight ||
          prevScaleDataRef.current.netWeight !== data.netWeight ||
          prevScaleDataRef.current.totalWeightUnit !== data.totalWeightUnit ||
          prevScaleDataRef.current.singleWeightUnit !== data.singleWeightUnit ||
          prevScaleDataRef.current.quantityUnit !== data.quantityUnit ||
          prevScaleDataRef.current.tareWeightUnit !== data.tareWeightUnit ||
          prevScaleDataRef.current.netWeightUnit !== data.netWeightUnit || 
          prevScaleDataRef.current.stable !== data.stable;


        if (hasChanged) {
          prevScaleDataRef.current = data;
          setScaleData({
            totalWeight: data.totalWeight,
            totalWeightUnit: data.totalWeightUnit,
            singleWeight: data.singleWeight,
            singleWeightUnit: data.singleWeightUnit,
            quantity: data.quantity,
            quantityUnit: "pcs",
            tareWeight: data.tareWeight,
            tareWeightUnit: data.tareWeightUnit,
            netWeight: data.netWeight,
            netWeightUnit: data.netWeightUnit,
            status: data.stable
              ? t("scale.measurement.stable")
              : t("scale.measurement.unstable"),
          });
        }
      };
    });
  }, [devices, t]);

  React.useEffect(() => {
    if (error) {
      toast({
        title: t("common.error"),
        description: error.message,
        variant: "destructive",
      });
    }

    if (jsonData) {
      setItemDetails(jsonData);
      handleSave();
    }

    console.log(jsonData, error);
  }, [jsonData, error, handleSave, toast, t]);

  React.useEffect(() => {
    if (isBarcodeScannerMode && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isBarcodeScannerMode]);

  return (
    <div className="flex flex-col gap-8">
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-bold">
              {t("scale.total_weight")}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-4xl font-bold">{scaleData.totalWeight} g</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-bold">
              {t("scale.single_weight")}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-4xl font-bold">{scaleData.singleWeight} g</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-bold">
              {t("scale.quantity")}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-4xl font-bold">{scaleData.quantity} pcs</p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-bold">
              {t("scale.tare_weight")}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-4xl font-bold ">{scaleData.tareWeight} g</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-bold">
              {t("scale.net_weight")}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-4xl font-bold">{scaleData.netWeight} g</p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-bold">
              {t("scale.scale_status")}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-xl font-bold">{scaleData.status}</p>
          </CardContent>
        </Card>
      </div>

      <div className="flex justify-end gap-4">
        <div className="flex items-center gap-2">
          <Label htmlFor="scanner-mode">
            {t("scale.barcode_scanner_mode")}
          </Label>
          <Switch
            id="scanner-mode"
            checked={isBarcodeScannerMode}
            onCheckedChange={handleScannerModeChange}
            disabled={isQRPreviewMode}
          />
        </div>
        <div className="flex items-center gap-2">
          <Label htmlFor="qr-preview-mode">{t("scale.qr_preview_mode")}</Label>
          <Switch
            id="qr-preview-mode"
            checked={isQRPreviewMode}
            onCheckedChange={setIsQRPreviewMode}
          />
        </div>
      </div>

      {isQRPreviewMode ? (
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-bold">
              {t("scale.qr_code_preview")}
            </CardTitle>
            <CardDescription>
              {t("scale.qr_code_preview_description")}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                {qrFields.map((field, index) => (
                  <div key={field.key} className="mb-4">
                    <Label htmlFor={`qr-field-${index}`}>{field.label}</Label>
                    <div className="flex gap-2">
                      <Input
                        id={`qr-field-${index}`}
                        value={field.value}
                        onChange={(e) =>
                          handleQRFieldChange(index, e.target.value)
                        }
                        className="flex-grow"
                      />
                      {index > 1 && (
                        <Button
                          type="button"
                          variant="ghost"
                          size="icon"
                          onClick={() => handleRemoveQRField(index)}
                          className="h-10 w-10"
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
                <div className="flex gap-2 mt-4">
                  <Input
                    value={newFieldName}
                    onChange={(e) => setNewFieldName(e.target.value)}
                    placeholder={t("scale.new_field_name")}
                    className="flex-grow"
                  />
                  <Button
                    type="button"
                    onClick={handleAddQRField}
                    disabled={!newFieldName}
                    className="whitespace-nowrap"
                  >
                    <PlusCircle className="mr-2 h-4 w-4" />
                    {t("scale.add_field")}
                  </Button>
                </div>
                <Button
                  type="button"
                  onClick={handleClearAllQRFields}
                  variant="outline"
                  className="mt-4"
                >
                  {t("scale.clear_all_fields")}
                </Button>
              </div>
              <div className="flex justify-center items-center">
                <QRCodeSVG value={generateQRData()} size={256} />
              </div>
            </div>
          </CardContent>
        </Card>
      ) : isBarcodeScannerMode ? (
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-bold">
              {t("scale.barcode_scanner")}
            </CardTitle>
            <CardDescription>
              {t("scale.barcode_scanner_description")}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Textarea
              ref={textareaRef}
              value={scannedData}
              onChange={handleBarcodeScan}
              placeholder={t("scale.scan_barcode")}
              className="mb-4"
              rows={4}
            />
            {lastScannedData && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">
                  {t("scale.last_scanned_data")}
                </h3>
                <pre className="bg-gray-100 p-2 rounded">
                  {JSON.stringify(lastScannedData, null, 2)}
                </pre>
                <Button
                  onClick={handleDeleteLastScannedData}
                  variant="destructive"
                  size="sm"
                  className="mt-2"
                >
                  <Trash2 className="mr-2 h-4 w-4" />
                  {t("scale.delete_last_scanned")}
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <div className="flex justify-between items-center">
              <div>
                <CardTitle className="text-xl font-bold">
                  {t("scale.item_details")}
                </CardTitle>
                <CardDescription>
                  {t("scale.item_details_description")}
                </CardDescription>
              </div>
              <div className="flex gap-2">
                <Button
                  onClick={handleSave}
                  disabled={loading || devices.scales.length === 0}
                >
                  {t("scale.save")}
                </Button>
                <Button
                  onClick={handleClear}
                  variant="outline"
                  disabled={loading}
                >
                  {t("scale.clear")}
                </Button>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSave} className="space-y-4">
              <div className="space-y-2">
                <Label
                  htmlFor="inventoryItemNo"
                  className="text-sm font-medium"
                >
                  {t("scale.inventory_item_no")}
                </Label>
                <Input
                  id="inventoryItemNo"
                  value={inventoryItemNo}
                  onChange={handleInventoryItemNoChange}
                  placeholder={t("scale.enter_inventory_item_no")}
                  ref={(el) => (inputRefs.current.inventoryItemNo = el)}
                />
                {inventoryHelperText && (
                  <p className="text-sm text-muted-foreground mt-2">{inventoryHelperText}</p>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(itemDetails).map(
                  ([field, { label, value, reuse }]) => (
                    <div key={field} className="space-y-2">
                      <div className="flex justify-between items-center">
                        <Label htmlFor={field} className="text-sm font-medium">
                          {label}
                        </Label>
                        <div className="flex">
                          {field === "productNo" && (
                            <div className="flex items-center gap-1">
                              <Switch
                                id="allow-duplicates"
                                checked={allowDuplicates}
                                onCheckedChange={setAllowDuplicates}
                                className="scale-75"
                              />
                              <Label
                                htmlFor="allow-duplicates"
                                className="text-xs"
                              >
                                {t("scale.allow_duplicates")}
                              </Label>
                            </div>
                          )}
                          <div className="flex items-center gap-1">
                            <Switch
                              id={`reuse-${field}`}
                              checked={reuse}
                              onCheckedChange={() => handleSwitchChange(field)}
                              className="scale-75"
                            />
                            <Label
                              htmlFor={`reuse-${field}`}
                              className="text-xs"
                            >
                              {t("scale.reuse")}
                            </Label>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <Input
                          id={field}
                          value={value}
                          onChange={(e) =>
                            handleInputChange(field, e.target.value)
                          }
                          className="flex-grow"
                          onKeyDown={(e) => handleKeyDown(e, field)}
                          ref={(el) => (inputRefs.current[field] = el)}
                        />
                        {field !== "productNo" && field !== "productName" && (
                          <Button
                            type="button"
                            variant="ghost"
                            size="icon"
                            onClick={() => handleRemoveField(field)}
                            className="h-10 w-10"
                            disabled={loading}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="flex gap-2 mt-6">
                <Input
                  value={newFieldName}
                  onChange={(e) => setNewFieldName(e.target.value)}
                  placeholder={t("scale.new_field_name")}
                  className="flex-grow"
                />
                <Button
                  type="button"
                  onClick={handleAddField}
                  disabled={!newFieldName || loading}
                  className="whitespace-nowrap"
                >
                  <PlusCircle className="mr-2 h-4 w-4" />
                  {t("scale.add_field")}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Scale;
