import CommunicationAdapter from "./Communication";

export class BluetoothAdapter extends CommunicationAdapter {
  constructor(device, serviceUuid, characteristicUuid, params) {
    super();
    this._device = device;
    this._characteristic = null;
    this._params = params || {};
    this._serviceUuid = serviceUuid;
    this._characteristicUuid = characteristicUuid;
    this._isReconnecting = false;
    this._reconnectAttempts = 0;
    this._maxReconnectAttempts = 3;
    this._advertisementData = {};
    
    // Bind the event handlers to maintain proper 'this' context
    this._handleDisconnection = this._handleDisconnection.bind(this);
    this._handleAdvertisement = this._handleAdvertisement.bind(this);
  }

  get device() {
    return this._device;
  }

  get connection_status() {
    return this._device.gatt.connected ? "connected" : "disconnected";
  }

  get advertisementData() {
    return this._advertisementData;
  }

  _handleDisconnection = async () => {
    console.log("Device disconnected, attempting to reconnect...");
    this._characteristic = null;
    
    if (!this._isReconnecting && this._reconnectAttempts < this._maxReconnectAttempts) {
      this._isReconnecting = true;
      this._reconnectAttempts++;
      
      try {
        await this.connect(this._serviceUuid, this._characteristicUuid);
        this._reconnectAttempts = 0; // Reset attempts on successful reconnection
      } catch (error) {
        console.error("Reconnection attempt failed:", error);
      } finally {
        this._isReconnecting = false;
      }
    }
  }

  _handleAdvertisement(event) {
    this._advertisementData.rssi = event.rssi;
    this._advertisementData.txPower = event.txPower;
    this._advertisementData.uuids = event.uuids;
  }

  async connect(serviceUuid, characteristicUuid) {
    try {
      // Store UUIDs for reconnection
      this._serviceUuid = serviceUuid;
      this._characteristicUuid = characteristicUuid;

      // Remove any existing event listeners before adding new ones
      this._device.removeEventListener("gattserverdisconnected", this._handleDisconnection);
      this._device.removeEventListener("advertisementreceived", this._handleAdvertisement);

      const server = await this._device.gatt.connect();
      const service = await server.getPrimaryService(serviceUuid);
      this._characteristic = await service.getCharacteristic(characteristicUuid);

      // Add event listeners after successful connection
      this._device.addEventListener("gattserverdisconnected", this._handleDisconnection);
      this._device.addEventListener("advertisementreceived", this._handleAdvertisement);

      try {
        await this._device.watchAdvertisements();
      } catch (error) {
        console.warn("Failed to watch advertisements:", error);
        // Don't fail the connection if advertisement watching fails
      }

      return true;
    } catch (error) {
      console.error("Connection failed:", error);
      throw error;
    }
  }

  async disconnect() {
    try {
      // Clean up event listeners
      this._device.removeEventListener("gattserverdisconnected", this._handleDisconnection);
      this._device.removeEventListener("advertisementreceived", this._handleAdvertisement);

      // Disconnect GATT if connected
      if (this._device && this._device.gatt.connected) {
        await this._device.gatt.disconnect();
      }

      this._characteristic = null;
      this._reconnectAttempts = 0;
      this._isReconnecting = false;
      this._advertisementData = {};
    } catch (error) {
      console.error("Disconnect failed:", error);
      throw error;
    }
  }

  async receiveData() {
    const value = await this._characteristic.readValue();
    return value;
  }

  async notify(callback) {
    await this._characteristic.startNotifications();
    this._characteristic.addEventListener(
      "characteristicvaluechanged",
      callback
    );
  }

  async sendData(data) {
    let buffer = data;

    if (typeof data === "string") {
      buffer = new TextEncoder().encode(data);
    } else if (Array.isArray(data)) {
      buffer = new Uint8Array(data);
    }

    await this._characteristic.writeValue(buffer);
  }
}

export default BluetoothAdapter;
